.Header {
    display: flex;
    width: 100%;
}
 
.Header-Logo {
    justify-content: flex-start;
}
.Header-Filler {
    flex-grow: 1;
}
.Header-Greeting {
    border: solid #DDDDDD 1px;
    border-radius: 10px;
    color: #11407F;
    font-size: large;
    padding: 20px;
    justify-content: flex-end;
    flex-shrink: 1;
}