.Solution-Item {
    background-color: #FFFFFF;
    border: solid #DDDDDD 1px;
    border-radius: 10px;
    padding: 50px;
}

.SolutionItem-Logo {
    height: 50px;
}

.SolutionItem-Title {
    color: #3A82F7;
}

.SolutionItem-Description {
    font-size: large;
}

.SolutionItem-Button {
    background-color: #1D417B;
}

.SolutionItem-Button-Error {
    background-color: #7b1d1d;
}
